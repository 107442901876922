import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { authConfig } from './auth.config';
import { AuthResponse } from './dtos';
import { USER_HAS_LOGGED_IN_KEY } from './shared/web-storage-keys';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly http = inject(HttpClient);
  private authUrl = `${environment.apiUrl}/auth`;

  constructor() {
    this.oidcSecurityService.isAuthenticated$.pipe(
      takeUntilDestroyed()
    ).subscribe();
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  register() {
    // Store a state flag so we can check this during startup in main.ts
    this.oidcSecurityService.setState('signup').subscribe();

    const signupUrl = new URL(environment.signupUrl);
    const params = new URLSearchParams({
      response_type: 'code',
      client_id: authConfig.clientId!,
      redirect_uri: window.location.origin,
      scope: authConfig.scope!,
      state: 'signup'
    });

    signupUrl.search = params.toString();
    window.location.href = signupUrl.toString();
  }

  logout(): void {
    this.http.delete<AuthResponse>(this.authUrl).pipe(
      catchError(() => {
        return of(null);
      }),
      switchMap(() => this.oidcSecurityService.logoff('glg', {
        customParams: {
          client_id: authConfig.clientId!,
          logout_uri: authConfig.postLogoutRedirectUri!,
          response_type: authConfig.responseType!
        }
      })),
    ).subscribe();
  }

  cookieAuth(idToken: string): Observable<AuthResponse> {
    // If we've authenticated then we're obviously not a first time user.
    localStorage.setItem(USER_HAS_LOGGED_IN_KEY, 'true');

    return this.http.post<AuthResponse>(this.authUrl, {
      idToken,
      localDev: !environment.production
    })
      .pipe(
        catchError(() => {
          this.login();
          return of({} as AuthResponse);
        })
      );
  }

  get email(): string {
    return this.oidcSecurityService.userData().userData.email;
  }
}
