/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { NewRelicErrorHander } from '../new-relic';
import { environment } from 'src/environments/environment';

declare const NREUM: NewRelicErrorHander;

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  logEvent(eventName: string, params: any) {
    if (environment.production) {
      NREUM.addPageAction(eventName, params);
    } else {
      console.log('Logged event', eventName, params);
    }
  }
}

export const logEvent = (category: string, event: string) => (
  _target: any,
  _propertyKey: string,
  descriptor: TypedPropertyDescriptor<any>
) => {
  const originalMethod = descriptor.value;

  descriptor.value = function (...args: any[]) {
    const loggingService = new LoggingService();

    loggingService.logEvent(event, { event_category: category });

    const result = originalMethod.apply(this, args);

    return result;
  };

  return descriptor;
};
