import { Unit } from './unit';

export class Quantity {
	amount: number;
	unit: Unit;

	constructor(amount: number, unit: Unit) {
		this.amount = amount;
		this.unit = unit;
	}
}
