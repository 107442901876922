import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  map,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { Ingredient, Item, Quantity } from '../dtos';
import { safeAdd, safeSubtract } from '../functions/safe-calc';
import { sortBy } from '../functions/sort-by';
import { ItemsService } from './items.service';
import { UnitService } from './unit.service';

@Injectable({
  providedIn: 'root',
})
export class ItemsStoreService {
  private itemsSubject = new BehaviorSubject<Item[]>([]);

  items$ = this.itemsSubject.asObservable().pipe(
    map(items => items.sort(sortBy('name')))
  );
  loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private itemsService: ItemsService,
    private unitService: UnitService
  ) {
    this.loading$.next(true);
    itemsService.getItems().subscribe((items) => {
      this.itemsSubject.next(items);
      this.loading$.next(false);
    });
  }

  readonly itemsOnShoppingList$: Observable<Item[]> = this.items$.pipe(
    map((items) => items.filter((item) => item.shoppingListAmount > 0))
  );

  addItemToShoppingList(item: Item) {
    item.isInShoppingCart = false;
    item.shoppingListAmount = safeAdd(item.shoppingListAmount, item.shoppingAmount);

    this.updateItem(item);
  }

  removeItemFromShoppingList(item: Item) {
    item.isInShoppingCart = false;
    item.shoppingListAmount = safeSubtract(item.shoppingListAmount, item.shoppingAmount);
    if (item.shoppingListAmount < 0) {
      item.shoppingListAmount = 0;
    }

    this.updateItem(item);
  }

  addIngredientToShoppingList(ingredient: Ingredient): Observable<Item> {
    const item = this.getItems().find(i => i.sk === ingredient.itemId);
    if (!item) {
      return of({} as Item);
    }
    const amount = safeAdd(item.shoppingListAmount, this.getShoppingListAmount(ingredient, item));
    item.shoppingListAmount = amount;

    const obs$ = this.updateItem(item);

    obs$.subscribe();

    return obs$;
  }

  removeIngredientFromShoppingList(ingredient: Ingredient): Observable<Item> {
    const obs$ = of(undefined).pipe(
      withLatestFrom(this.items$),
      map(([, items]) => items.find(i => i.sk === ingredient.itemId) as Item),
      tap((item: Item) => {
        const amount = safeSubtract(item.shoppingListAmount, this.getShoppingListAmount(ingredient, item));
        item.shoppingListAmount = amount;
        item.isInShoppingCart = false;
        if (item.shoppingListAmount < 0) {
          item.shoppingListAmount = 0;
        }
        this.updateItem(item);
      })
    );

    obs$.subscribe();

    return obs$;
  }

  resetShoppingList(resetInCartOnly: boolean): void {
    this.getItems()
      .filter(i => i.shoppingListAmount !== 0 && (i.isInShoppingCart || !resetInCartOnly))
      .forEach(item => this.reset(item));
  }

  reset(item: Item): Observable<Item> {
    item.shoppingListAmount = 0;
    item.isInShoppingCart = false;

    return this.updateItem(item);
  }

  createItem(item: Item): Observable<Item> {
    const obs$ = this.itemsService.createItem(item);

    this.loading$.next(true);
    obs$.subscribe(
      (createdItem) => {
        const items = [...this.getItems(), createdItem];
        this.itemsSubject.next(items);
      },
      () => { /* nothing to see here */ },
      () => this.loading$.next(false)
    );

    return obs$;
  }

  deleteItem(item: Item): Observable<void> {
    const obs$ = this.itemsService.deleteItem(item);

    obs$.subscribe(() => {
      const items = this.getItems().filter((i) => i.sk !== item.sk);
      this.itemsSubject.next(items);
    });

    return obs$;
  }

  updateItem(item: Item): Observable<Item> {
    const obs$ = this.itemsService.updateItem(item);

    obs$.subscribe(() => {
      const items = this.getItems();
      const index = items.findIndex((i) => i.sk === item.sk);
      items[index] = item;
      this.itemsSubject.next(items);
    });

    return obs$;
  }

  private getShoppingListAmount(ingredient: Ingredient | Ingredient, item: Item): number {
    const ingredientQuantity = new Quantity(
      ingredient.recipeAmount,
      ingredient.recipeUnit
    );
    const shoppingListQuantity = this.unitService.convert(
      ingredientQuantity,
      item.shoppingUnit
    );

    return shoppingListQuantity.amount;
  }

  private getItems(): Item[] {
    return this.itemsSubject.getValue();
  }

  canDeleteAisle(aisleId?: string): boolean {
    const items = this.getItems().filter(i => i.aisleId === aisleId);
    return items.length === 0;
  }

  findItemByName(name: string): Item | undefined {
    return this.getItems()
      .find(i => i.name.toLowerCase() === name.toLowerCase());
  }
}
