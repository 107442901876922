export enum Unit {
	Teaspoon = 1,
	TableSpoon = 2,
	Cup = 3,
	Item = 4,
	Gram = 1000,
	Ounce = 1700,
	Pound = 1100,
	Kilo = 1200,
	Milliliter = 2000,
	Liter = 2400,
	Gallon = 2700
}
