<div>
  {{"demo.help_text" | transloco }}

  <button mat-flat-button
          data-test="first-time-login"
          (click)="login()">
    {{ "demo.help_login" | transloco }}
  </button>

  {{ "demo.help_or" | transloco }}

  <button mat-flat-button
          data-test="first-time-register"
          (click)="register()">
    {{ "demo.help_register" | transloco }}
  </button>
</div>
