import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, shareReplay, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserDto, UserInfo, UserModel } from '../dtos';
import { toUser } from '../mappers/user-mapper';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private usersUrl = `${environment.apiUrl}/users`;

  constructor(
    private http: HttpClient
  ) { }

  getUserInfo(): Observable<UserInfo> {
    return this.http.get<UserDto>(this.usersUrl)
      .pipe(
        shareReplay(),
        map(dto => {
          const userInfo: UserInfo = { status: 'active', email: dto.userEmail };
          return userInfo;
        }),
        catchError(err => {
          if (!err.status || err.status === 404 || err.status === 406) {
            const userInfo: UserInfo = { status: 'new' };
            return of(userInfo);
          }
          if (err.status === 401) {
            return of({ status: null });
          }
          return throwError(() => new Error('Error calling user endpoint'));
        })
      );
  }

  createUser(email: string): Observable<UserModel> {
    const dto: UserDto = { userEmail: email };

    return this.http.post<UserDto>(this.usersUrl, dto)
      .pipe(
        map(res => toUser(res)),
        shareReplay());
  }

  deleteUser(): Observable<{ message: string }> {
    return this.http.delete<{ message: string }>(this.usersUrl)
      .pipe(shareReplay());
  }
}
