import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { combineLatest, filter, map, Observable, of } from "rxjs";
import { isFirstTimeUser } from "./first-time-user/is-first-time-user";
import { UserStoreService } from "./services/user-store.service";

@Injectable()
export class ActiveUserGuard {

  constructor(
    private userStore: UserStoreService,
    private router: Router) { }

  canActivate(): Observable<boolean | UrlTree> {
    const firstTimeUser$ = of(isFirstTimeUser());
    const isActive$ = this.userStore.status$.pipe(
      filter(Boolean),
      map(status => status === 'active'));

    return combineLatest([firstTimeUser$, isActive$])
      .pipe(map(([firstTimeUser, isActive]) => {
        return firstTimeUser || isActive || this.router.parseUrl('/welcome');
      }));
  }

}
