import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { ActiveUserGuard } from './active-user.guard';
import { GlobalErrorHandler } from './error-handler';
import { FailedRequestsInterceptor } from './interceptors/failed-requests.interceptor';
import { Hotkeys } from './services/hotkeys.service';
import { PwaService } from './services/pwa.service';

@NgModule({
  imports: [],
  providers: [
    ActiveUserGuard,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        // Pre-load the Hotkeys service, so that the SHIFT+? hotkey to show the
        // shortcuts dialog is always available, even if you deep-link into a
        // page where the Hotkeys service is not loaded.
        { provide: APP_INITIALIZER, deps: [Hotkeys], multi: true, useFactory: () => () => null },
        { provide: APP_INITIALIZER, deps: [PwaService], multi: true, useFactory: (p: PwaService) => () => p.initInstallPrompt() },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: FailedRequestsInterceptor, multi: true },
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
