import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';
import { Observable, tap } from 'rxjs';
import { ErrorMessageComponent } from '../error-message-component/error-message.component';
import { AuthService } from '../auth.service';

@Injectable()
export class FailedRequestsInterceptor implements HttpInterceptor {

  constructor(
    private snackBar: MatSnackBar,
    private transloco: TranslocoService,
    private authService: AuthService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(tap({
        error: (error: HttpErrorResponse) => this.handleError(error)
      }));
  }

  private handleError(httpErrorResponse: HttpErrorResponse) {
    if (httpErrorResponse.status === 401) {
      this.authService.login();
      return;
    }

    if (this.requiresCustomErrorHandling(httpErrorResponse)) {
      return;
    }

    const errorMessage = this.transloco.translate('errors.failed-request');
    const action = this.transloco.translate('refresh');
    const snackbar$ = this.snackBar.openFromComponent(ErrorMessageComponent, {
      data: {
        errorMessage,
        action
      },
      duration: 8000
    });

    snackbar$.afterDismissed().subscribe(dismiss => {
      if (dismiss.dismissedByAction) {
        window.location.reload();
      }
    });
  }

  private requiresCustomErrorHandling(httpErrorResponse: HttpErrorResponse): boolean {
    const { url, status } = httpErrorResponse || {};

    // The status 200 check does not make sense of course but it sometimes happens in the e2e tests. I'm guessing it's cypress intercepting the request....
    return (url?.includes('users') || [0, 200].includes(status));
  }
}
