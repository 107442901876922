import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ItemDto, Item } from '../dtos';
import { toDto, toItem } from '../mappers/item-mapper';

@Injectable({
  providedIn: 'root',
})
export class ItemsService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getItems(): Observable<Item[]> {
    return this.get<ItemDto[]>('items')
      .pipe(map(items => items.map(toItem)));
  }

  createItem(item: Item): Observable<Item> {
    return this.post('items', toDto(item))
      .pipe(map(toItem));
  }

  deleteItem(item: Item): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/items/${item.sk}`).pipe(
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );
  }

  updateItem(item: Item): Observable<Item> {
    return this.http
      .put<ItemDto>(`${this.baseUrl}/items/${item.sk}`, toDto(item))
      .pipe(
        map(toItem),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      );
  }

  private get<T>(path: string) {
    return this.http.get<T>(`${this.baseUrl}/${path}`);
  }

  private post<T>(path: string, body: T) {
    return this.http.post<T>(`${this.baseUrl}/${path}`, body).pipe(
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );
  }
}
