import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthService } from '../auth.service';
import { UserModel, UserStatus } from "../dtos";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class UserStoreService {
  private statusSubject = new BehaviorSubject<UserStatus>(null);
  private emailSubject = new BehaviorSubject<string | null>(null);

  status$ = this.statusSubject.asObservable();
  email$ = this.emailSubject.asObservable();

  constructor(
    private userService: UserService,
    private authService: AuthService
  ) {
    userService.getUserInfo().subscribe(userInfo => {
      this.statusSubject.next(userInfo.status);
      this.emailSubject.next(userInfo.email || null);
    });
  }

  createUser(): Observable<UserModel> {
    const email = this.authService.email;
    const obs$ = this.userService.createUser(email);

    obs$.subscribe(() => {
      this.statusSubject.next('active');
      this.emailSubject.next(email);
    });

    return obs$;
  }

  deleteUser(): Observable<{ message: string }> {
    return this.userService.deleteUser();
  }
}
