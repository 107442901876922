/**
 * Returns x + y, but corrects for floating point errors.
 */
export const safeAdd = (x: number, y: number): number => correctFloatError((x + y))

/**
 * Returns x - y, but corrects for floating point errors.
 */
export const safeSubtract = (x: number, y: number): number => correctFloatError((x - y))

export const correctFloatError = (x: number): number => parseFloat(x.toFixed(4))
