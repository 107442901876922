import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  translocoConfig,
  TranslocoModule,
  TranslocoService,
  provideTransloco,
} from '@jsverse/transloco';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { lastValueFrom } from 'rxjs';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { LANGUAGE_KEY } from './shared/web-storage-keys';

export const supportedLanguages = ['en', 'nl'];

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) { }

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

export function preloadDefault(transloco: TranslocoService) {
  return function () {
    const storedLanguage = localStorage.getItem(LANGUAGE_KEY);
    const lang = !storedLanguage || storedLanguage === 'default'
      ? getBrowserLanguageOrDefault()
      : storedLanguage;

    transloco.setActiveLang(lang);
    return lastValueFrom(transloco.load(lang));
  };
}

export function getBrowserLanguageOrDefault() {
  let browserLanguage = navigator.language;
  if (browserLanguage.length > 2 && browserLanguage.includes('-')) {
    browserLanguage = browserLanguage.split('-')[0];
  }

  return supportedLanguages.includes(browserLanguage)
    ? browserLanguage
    : supportedLanguages[0];
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: translocoConfig({
        availableLangs: supportedLanguages,
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      })
    }),
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: preloadDefault,
      deps: [TranslocoService],
    },
    provideTranslocoMessageformat()
  ],
})
export class TranslocoRootModule { }
