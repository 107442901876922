import { UserDto, UserModel } from "../dtos";

export const toDto = (user: UserModel): UserDto => {
  return {
    userEmail: user.email
  }
}

export const toUser = (dto: UserDto): UserModel => {
  return {
    email: dto.userEmail
  }
}