<mat-toolbar color="primary">
  <span>{{'install-header' | transloco}}</span>
  <span class="spacer"></span>
  <button mat-icon-button
          (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>

<div *ngIf="data.mobileType === 'android'"
     class="android-prompt">
  <div>
    {{'install-android' | transloco}}
  </div>
  <button mat-raised-button
          color="accent"
          (click)="installPwa()">
    {{'install-to-home-screen' | transloco}}
  </button>
  <button mat-button
          (click)="dontAskAgain()">
    {{'install-dont-ask-again' | transloco}}
  </button>
</div>

<div *ngIf="data.mobileType === 'ios'"
     class="ios-prompt">
  <div>{{'install-ios' | transloco}}</div>
  <div>
    <img src="./assets/ios-menu-btn.png">
    <mat-icon>navigate_next</mat-icon>
    <img src="./assets/ios-add-btn.png">
  </div>
  <div>
    <button mat-button
            (click)="dontAskAgain()">
      {{'install-dont-ask-again' | transloco}}
    </button>
  </div>
</div>
