import { ErrorHandler, Injectable } from '@angular/core';
import { NewRelicErrorHander } from './new-relic';
import { environment } from 'src/environments/environment';

declare const NREUM: NewRelicErrorHander;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(error: any) {
    if (environment.production) {
      NREUM.noticeError(error);
    } else {
      console.error('Logged error', error);
    }
  }
}
