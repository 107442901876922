import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

export const authConfig: OpenIdConfiguration = {
  configId: 'glg',
  authority: environment.authIssuer,
  redirectUrl: window.location.origin,
  postLogoutRedirectUri: window.location.origin,
  secureRoutes: [environment.apiUrl],
  clientId: '4t144kpaudfrbeoljae277o86',
  scope: 'openid glg.api/glg.api',
  responseType: 'code',
  silentRenew: false,
  useRefreshToken: false,
  logLevel: LogLevel.Error
};
