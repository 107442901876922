import { DOCUMENT } from '@angular/common';
import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(
    appRef: ApplicationRef,
    private updates: SwUpdate,
    private alertService: AlertService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    if (updates.isEnabled) {
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

      everySixHoursOnceAppIsStable$.subscribe(async () => {
        await updates.checkForUpdate();
      });
    }
  }

  checkForUpdates(): void {
    this.updates.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(() => this.promptToUpdate());

    this.updates.unrecoverable
      .subscribe(() => {
        this.forceUpdate();
      });
  }

  private promptToUpdate(): void {
    this.alertService.confirm('updates.title', 'updates.message', 'updates.install')
      .subscribe(res => {
        if (res) {
          this.document.location.reload();
        }
      });
  }

  private forceUpdate(): void {
    this.alertService
      .alert('updates.forced-title', 'updates.forced-message', 'updates.forced-install')
      .subscribe(() => {
        this.document.location.reload();
      })
  }
}
