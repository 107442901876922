import { Injectable } from '@angular/core';
import { Unit } from '../dtos';
import { Quantity } from '../dtos';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  private conversionTable: Dictionary<Dictionary<(input: number) => number>> = {};

  convert(source: Quantity, targetUnit: Unit): Quantity {
    const convertedAmount = this.conversionTable[Unit[source.unit]][Unit[targetUnit]](source.amount);

    return new Quantity(convertedAmount, targetUnit);
  }

  private registerUnitConversion(source: Unit, target: Unit, conversionFunction: (input: number) => number) {

    if (!Object.prototype.hasOwnProperty.call(this.conversionTable, Unit[source])) {
      this.conversionTable[Unit[source]] = {};
    }
    this.conversionTable[Unit[source]][Unit[target]] = conversionFunction;
  }

  constructor() {
    this.registerUnitConversion(Unit.Teaspoon, Unit.Teaspoon, (input: number) => input * 1);
    this.registerUnitConversion(Unit.Teaspoon, Unit.TableSpoon, (input: number) => input * 0.33);
    this.registerUnitConversion(Unit.Teaspoon, Unit.Cup, (input: number) => input * 0.04);
    this.registerUnitConversion(Unit.Teaspoon, Unit.Gram, (input: number) => input * 5);
    this.registerUnitConversion(Unit.Teaspoon, Unit.Milliliter, (input: number) => input * 5);
    this.registerUnitConversion(Unit.Teaspoon, Unit.Ounce, (input: number) => input * 0.1786);
    this.registerUnitConversion(Unit.Teaspoon, Unit.Pound, (input: number) => input * 0.01);
    this.registerUnitConversion(Unit.Teaspoon, Unit.Kilo, (input: number) => input * 0.005);
    this.registerUnitConversion(Unit.Teaspoon, Unit.Liter, (input: number) => input * 0.005);
    this.registerUnitConversion(Unit.Teaspoon, Unit.Gallon, (input: number) => input * 0.00133);
    this.registerUnitConversion(Unit.Teaspoon, Unit.Item, (input: number) => input * 0.02);

    this.registerUnitConversion(Unit.TableSpoon, Unit.Teaspoon, (input: number) => input * 3);
    this.registerUnitConversion(Unit.TableSpoon, Unit.TableSpoon, (input: number) => input * 1);
    this.registerUnitConversion(Unit.TableSpoon, Unit.Cup, (input: number) => input * 0.12);
    this.registerUnitConversion(Unit.TableSpoon, Unit.Gram, (input: number) => input * 15);
    this.registerUnitConversion(Unit.TableSpoon, Unit.Milliliter, (input: number) => input * 15);
    this.registerUnitConversion(Unit.TableSpoon, Unit.Ounce, (input: number) => input * 0.5357142857);
    this.registerUnitConversion(Unit.TableSpoon, Unit.Pound, (input: number) => input * 0.03);
    this.registerUnitConversion(Unit.TableSpoon, Unit.Kilo, (input: number) => input * 0.015);
    this.registerUnitConversion(Unit.TableSpoon, Unit.Liter, (input: number) => input * 0.015);
    this.registerUnitConversion(Unit.TableSpoon, Unit.Gallon, (input: number) => input * 0.004);
    this.registerUnitConversion(Unit.TableSpoon, Unit.Item, (input: number) => input * 0.06);

    this.registerUnitConversion(Unit.Gram, Unit.Teaspoon, (input: number) => input * 0.2);
    this.registerUnitConversion(Unit.Gram, Unit.TableSpoon, (input: number) => input * 0.066);
    this.registerUnitConversion(Unit.Gram, Unit.Cup, (input: number) => input * 0.008);
    this.registerUnitConversion(Unit.Gram, Unit.Gram, (input: number) => input * 1);
    this.registerUnitConversion(Unit.Gram, Unit.Milliliter, (input: number) => input * 1);
    this.registerUnitConversion(Unit.Gram, Unit.Ounce, (input: number) => input * 0.0357);
    this.registerUnitConversion(Unit.Gram, Unit.Pound, (input: number) => input * 0.002);
    this.registerUnitConversion(Unit.Gram, Unit.Kilo, (input: number) => input * 0.001);
    this.registerUnitConversion(Unit.Gram, Unit.Liter, (input: number) => input * 0.001);
    this.registerUnitConversion(Unit.Gram, Unit.Gallon, (input: number) => input * 0.000266);
    this.registerUnitConversion(Unit.Gram, Unit.Item, (input: number) => input * 0.004);

    this.registerUnitConversion(Unit.Milliliter, Unit.Teaspoon, (input: number) => input * 0.2);
    this.registerUnitConversion(Unit.Milliliter, Unit.TableSpoon, (input: number) => input * 0.066);
    this.registerUnitConversion(Unit.Milliliter, Unit.Cup, (input: number) => input * 0.008);
    this.registerUnitConversion(Unit.Milliliter, Unit.Gram, (input: number) => input * 1);
    this.registerUnitConversion(Unit.Milliliter, Unit.Milliliter, (input: number) => input * 1);
    this.registerUnitConversion(Unit.Milliliter, Unit.Ounce, (input: number) => input * 0.0357);
    this.registerUnitConversion(Unit.Milliliter, Unit.Pound, (input: number) => input * 0.002);
    this.registerUnitConversion(Unit.Milliliter, Unit.Kilo, (input: number) => input * 0.001);
    this.registerUnitConversion(Unit.Milliliter, Unit.Liter, (input: number) => input * 0.001);
    this.registerUnitConversion(Unit.Milliliter, Unit.Gallon, (input: number) => input * 0.000266);
    this.registerUnitConversion(Unit.Milliliter, Unit.Item, (input: number) => input * 0.004);

    this.registerUnitConversion(Unit.Ounce, Unit.Teaspoon, (input: number) => input * 5.6);
    this.registerUnitConversion(Unit.Ounce, Unit.TableSpoon, (input: number) => input * 1.866);
    this.registerUnitConversion(Unit.Ounce, Unit.Cup, (input: number) => input * 0.224);
    this.registerUnitConversion(Unit.Ounce, Unit.Gram, (input: number) => input * 28);
    this.registerUnitConversion(Unit.Ounce, Unit.Milliliter, (input: number) => input * 28);
    this.registerUnitConversion(Unit.Ounce, Unit.Ounce, (input: number) => input * 1);
    this.registerUnitConversion(Unit.Ounce, Unit.Pound, (input: number) => input * 0.056);
    this.registerUnitConversion(Unit.Ounce, Unit.Kilo, (input: number) => input * 0.028);
    this.registerUnitConversion(Unit.Ounce, Unit.Liter, (input: number) => input * 0.028);
    this.registerUnitConversion(Unit.Ounce, Unit.Gallon, (input: number) => input * 0.007466);
    this.registerUnitConversion(Unit.Ounce, Unit.Item, (input: number) => input * 0.112);

    this.registerUnitConversion(Unit.Pound, Unit.Teaspoon, (input: number) => input * 100);
    this.registerUnitConversion(Unit.Pound, Unit.TableSpoon, (input: number) => input * 33.33);
    this.registerUnitConversion(Unit.Pound, Unit.Cup, (input: number) => input * 4);
    this.registerUnitConversion(Unit.Pound, Unit.Gram, (input: number) => input * 500);
    this.registerUnitConversion(Unit.Pound, Unit.Milliliter, (input: number) => input * 500);
    this.registerUnitConversion(Unit.Pound, Unit.Ounce, (input: number) => input * 17.857);
    this.registerUnitConversion(Unit.Pound, Unit.Pound, (input: number) => input * 1);
    this.registerUnitConversion(Unit.Pound, Unit.Kilo, (input: number) => input * 0.5);
    this.registerUnitConversion(Unit.Pound, Unit.Liter, (input: number) => input * 0.5);
    this.registerUnitConversion(Unit.Pound, Unit.Gallon, (input: number) => input * 0.133);
    this.registerUnitConversion(Unit.Pound, Unit.Item, (input: number) => input * 2);

    this.registerUnitConversion(Unit.Kilo, Unit.Teaspoon, (input: number) => input * 200);
    this.registerUnitConversion(Unit.Kilo, Unit.TableSpoon, (input: number) => input * 66.66);
    this.registerUnitConversion(Unit.Kilo, Unit.Cup, (input: number) => input * 8);
    this.registerUnitConversion(Unit.Kilo, Unit.Gram, (input: number) => input * 1000);
    this.registerUnitConversion(Unit.Kilo, Unit.Milliliter, (input: number) => input * 1000);
    this.registerUnitConversion(Unit.Kilo, Unit.Ounce, (input: number) => input * 35.714);
    this.registerUnitConversion(Unit.Kilo, Unit.Pound, (input: number) => input * 2);
    this.registerUnitConversion(Unit.Kilo, Unit.Kilo, (input: number) => input * 1);
    this.registerUnitConversion(Unit.Kilo, Unit.Liter, (input: number) => input * 1);
    this.registerUnitConversion(Unit.Kilo, Unit.Gallon, (input: number) => input * 0.266);
    this.registerUnitConversion(Unit.Kilo, Unit.Item, (input: number) => input * 4);

    this.registerUnitConversion(Unit.Liter, Unit.Teaspoon, (input: number) => input * 200);
    this.registerUnitConversion(Unit.Liter, Unit.TableSpoon, (input: number) => input * 66.66);
    this.registerUnitConversion(Unit.Liter, Unit.Cup, (input: number) => input * 8);
    this.registerUnitConversion(Unit.Liter, Unit.Gram, (input: number) => input * 1000);
    this.registerUnitConversion(Unit.Liter, Unit.Milliliter, (input: number) => input * 1000);
    this.registerUnitConversion(Unit.Liter, Unit.Ounce, (input: number) => input * 35.714);
    this.registerUnitConversion(Unit.Liter, Unit.Pound, (input: number) => input * 2);
    this.registerUnitConversion(Unit.Liter, Unit.Kilo, (input: number) => input * 1);
    this.registerUnitConversion(Unit.Liter, Unit.Liter, (input: number) => input * 1);
    this.registerUnitConversion(Unit.Liter, Unit.Gallon, (input: number) => input * 0.266);
    this.registerUnitConversion(Unit.Liter, Unit.Item, (input: number) => input * 4);

    this.registerUnitConversion(Unit.Gallon, Unit.Teaspoon, (input: number) => input * 750);
    this.registerUnitConversion(Unit.Gallon, Unit.TableSpoon, (input: number) => input * 250);
    this.registerUnitConversion(Unit.Gallon, Unit.Cup, (input: number) => input * 30);
    this.registerUnitConversion(Unit.Gallon, Unit.Gram, (input: number) => input * 3750);
    this.registerUnitConversion(Unit.Gallon, Unit.Milliliter, (input: number) => input * 3750);
    this.registerUnitConversion(Unit.Gallon, Unit.Ounce, (input: number) => input * 133.928);
    this.registerUnitConversion(Unit.Gallon, Unit.Pound, (input: number) => input * 7.5);
    this.registerUnitConversion(Unit.Gallon, Unit.Kilo, (input: number) => input * 3.75);
    this.registerUnitConversion(Unit.Gallon, Unit.Liter, (input: number) => input * 3.75);
    this.registerUnitConversion(Unit.Gallon, Unit.Gallon, (input: number) => input * 1);
    this.registerUnitConversion(Unit.Gallon, Unit.Item, (input: number) => input * 15);

    this.registerUnitConversion(Unit.Item, Unit.Teaspoon, (input: number) => input * 50);
    this.registerUnitConversion(Unit.Item, Unit.TableSpoon, (input: number) => input * 16.66);
    this.registerUnitConversion(Unit.Item, Unit.Cup, (input: number) => input * 2);
    this.registerUnitConversion(Unit.Item, Unit.Gram, (input: number) => input * 250);
    this.registerUnitConversion(Unit.Item, Unit.Milliliter, (input: number) => input * 250);
    this.registerUnitConversion(Unit.Item, Unit.Ounce, (input: number) => input * 8.928);
    this.registerUnitConversion(Unit.Item, Unit.Pound, (input: number) => input * 0.5);
    this.registerUnitConversion(Unit.Item, Unit.Kilo, (input: number) => input * 0.25);
    this.registerUnitConversion(Unit.Item, Unit.Liter, (input: number) => input * 0.25);
    this.registerUnitConversion(Unit.Item, Unit.Gallon, (input: number) => input * 0.066);
    this.registerUnitConversion(Unit.Item, Unit.Item, (input: number) => input * 1);
  }
}

interface Dictionary<T> {
  [K: string]: T;
}
