import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslocoService } from "@jsverse/transloco";
import packageJson from "../../../package.json";
import { VERSION_KEY } from "../shared/web-storage-keys";

@Injectable({
  providedIn: "root",
})
export class ReleaseNotesService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly transloco: TranslocoService,
  ) {
  }

  checkReleaseNotes() {
    const lastKnownVersion = localStorage.getItem(VERSION_KEY) || "1.0.0";
    const currentVersion = packageJson.version;

    if (lastKnownVersion === currentVersion) {
      // The user has already seen the release notes for this version.
      return;
    }

    const releaseNotes = this.getNewReleaseNotes(lastKnownVersion);
    if (releaseNotes?.length <= 0) {
      // There are no release notes to show for this version.
      return;
    }

    if (localStorage.getItem(VERSION_KEY)) {
      // Only show the snackbar if the user has already seen a previous version.
      // If this is the first time that a version check is performed, the app
      // has just been installed and no release notes updates should be shown.
      // This might also help with flaky e2e tests, where sometimes this
      // snackbar message might cover a button that the e2e test tries to click.
      const version = releaseNotes[releaseNotes.length - 1];
      const versionKey = version.replace(/\./g, "-");
      const message = this.transloco.translate(`release-notes.version.${versionKey}`, { version });
      const action = this.transloco.translate("release-notes.dismiss");

      this.snackBar.open(message, action, { duration: 6000 });
    }

    localStorage.setItem(VERSION_KEY, currentVersion);
  }

  private getNewReleaseNotes(lastKnownVersion: string): string[] {
    const [currentMajor, currentMinor, currentPatch] = lastKnownVersion.split(".").map(Number);
    return this.getReleasedVersions().filter(version => {
      const [major, minor, patch] = version.split(".").map(Number);
      return major > currentMajor
        || (major === currentMajor && minor > currentMinor)
        || (major === currentMajor && minor === currentMinor && patch > currentPatch);
    });
  }

  /**
   * Returns an array of released version numbers, based on the information in
   * the i18n files. The version numbers are sorted in ascending order.
   *
   * @example ["1.0.0", "1.1.0", "1.2.0"]
   */
  private getReleasedVersions() {
    return Object.keys(this.transloco.translateObject("release-notes.version"))
      .map(v => v.replace(/-/g, "."))
      .sort((a, b) => a.localeCompare(b));
  }
}
