<div class="root-container">
  <nav mat-tab-nav-bar
       disableRipple="true"
       mat-align-tabs="center"
       [mat-stretch-tabs]="!wideScreen?.matches"
       disablePagination="true"
       [tabPanel]="tabPanel">
    <a mat-tab-link
       attr.data-test="tab-link-{{ link.path }}"
       *ngFor="let link of links"
       [routerLink]="link.path"
       (click)="activeLink = link"
       [active]="activeLink === link">
      <mat-icon [matBadge]="link.badgeCount$ | async"
                matBadgePosition="after"
                matBadgeOverlap="false"
                [matBadgeHidden]="wideScreen?.matches"
                aria-hidden="false">{{ link.icon }}</mat-icon>
      <span *ngIf="wideScreen?.matches"
            [matBadge]="link.badgeCount$ | async"
            matBadgePosition="after"
            matBadgeOverlap="false"
            [matBadgeHidden]="!wideScreen?.matches">{{ link.label | transloco
        }}</span>
    </a>
  </nav>

  <div class="router"
       #scrollContent>
    <mat-tab-nav-panel #tabPanel>
      <div [@triggerName]="prepareRoute(outlet)">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </mat-tab-nav-panel>
  </div>

  <app-first-time-user-bar *ngIf="isFirstTimeUser()"></app-first-time-user-bar>
</div>