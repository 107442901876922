/* eslint-disable @typescript-eslint/no-explicit-any */
export const sortBy = (propertyName: string) => {
  let sortOrder = 1;

  if (propertyName[0] === '-') {
    sortOrder = -1;
    propertyName = propertyName.substr(1);
  }

  return (a: any, b: any) => {
    let result = 0;

    const isString = typeof a[propertyName] === 'string' && typeof b[propertyName] === 'string';
    const x = isString ? (<string>a[propertyName]).toUpperCase() : a[propertyName];
    const y = isString ? (<string>b[propertyName]).toUpperCase() : b[propertyName];

    if (x < y) {
      result = -1;
    } else if (x > y) {
      result = 1;
    }

    return result * sortOrder;
  };
};
