import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslocoService } from "@jsverse/transloco";
import { take, Observable } from "rxjs";
import { AlertComponent } from "../shared/alert/alert.component";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private dialog: MatDialog,
    private transloco: TranslocoService
  ) { }

  alert(title: string, message: string, okText = 'ok'): Observable<void> {
    const translatedTitle = this.transloco.translate(title);
    const translatedMessage = this.transloco.translate(message);

    return this.alertRaw(translatedTitle, translatedMessage, okText);
  }

  alertRaw(title: string, message: string, okText = 'ok'): Observable<void> {
    const ref = this.dialog.open<void>(AlertComponent, {
      data: {
        title, message, okText
      }
    });

    return ref.afterClosed();
  }

  confirm(title: string, message: string, okText = 'ok', cancelText = 'cancel'): Observable<boolean> {
    return this.dialog
      .open(AlertComponent, { data: { title, message, okText, cancelText } })
      .afterClosed()
      .pipe(take(1));
  }
}
