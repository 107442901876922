import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { EventManager } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { HotkeysDialogComponent } from "../hotkeys-dialog/hotkeys-dialog.component";

export type Hotkey = {
  element: Element;
  keys: string;
  description: string | undefined;
}

@Injectable({ providedIn: 'root' })
export class Hotkeys {
  defaults: Partial<Hotkey> = {
    element: this.document
  }

  private hotkeys = new Map<string, string>();

  constructor(
    private eventManager: EventManager,
    @Inject(DOCUMENT) private document: Element,
    private dialog: MatDialog
  ) {
    this.addShortcut({ keys: 'shift.?' }).subscribe(() => {
      this.openHelpModal();
    });
  }

  addShortcut(options: Partial<Hotkey>) {
    const merged: Partial<Hotkey> = { ...this.defaults, ...options };
    const event = `keydown.${merged.keys}`;

    merged.description && this.hotkeys.set(merged.keys!, merged.description);

    return new Observable(observer => {
      const handler = (e: KeyboardEvent) => {
        if ((e.target as HTMLElement).tagName !== 'TEXTAREA') {
          e.preventDefault();
          observer.next(e);
        }
      };

      const dispose = this.eventManager.addEventListener(merged.element as HTMLElement, event, handler);

      return () => {
        dispose();
        this.hotkeys.delete(merged.keys!);
      };
    });
  }

  private openHelpModal() {
    this.dialog.open(HotkeysDialogComponent, {
      width: '500px',
      data: this.hotkeys,
      disableClose: false
    });
  }
}
