import { Component, inject, OnInit } from "@angular/core";
import { ACTIVE_TAB_KEY, USER_HAS_LOGGED_IN_KEY } from "../shared/web-storage-keys";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-demo',
  standalone: true,
  imports: [],
  template: ''
})
export class DemoComponent implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);

  ngOnInit() {
    localStorage.removeItem(USER_HAS_LOGGED_IN_KEY);
    localStorage.removeItem(ACTIVE_TAB_KEY);
    this.authService.logout();

    this.router.navigate(['/app/shoppinglist']);
  }
}
