<h1 mat-dialog-title>{{data.title | transloco}}</h1>

<div mat-dialog-content>
        <p>{{data.message | transloco}}</p>
</div>

<div mat-dialog-actions
     [align]="'end'">
        <button mat-button
                data-test="alert-cancel"
                (click)="cancel()"
                *ngIf="data.cancelText">{{data.cancelText| transloco}}</button>
        <button mat-raised-button
                data-test="alert-accept"
                color="accent"
                cdkFocusInitial
                (click)="ok()">{{ data.okText | transloco }}</button>
</div>