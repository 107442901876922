import { Platform } from "@angular/cdk/platform";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { take, timer } from "rxjs";
import { InstallPwaComponent } from "../install-pwa/install-pwa.component";
import { SKIP_PWA_INSTALL_PROMPT_KEY } from "../shared/web-storage-keys";

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private promptEvent?: Event;

  constructor(
    private platform: Platform,
    private bottomSheet: MatBottomSheet
  ) { }

  initInstallPrompt(): void {
    if (localStorage.getItem(SKIP_PWA_INSTALL_PROMPT_KEY) === 'true') {
      return;
    }

    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: Event) => {
        event.preventDefault();
        this.promptEvent = event;
        this.openPromptComponent('android');
      });
    }
    if (this.platform.IOS) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore 7053 (standalone is only available on Safari)
      const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
      if (!isInStandaloneMode) {
        this.openPromptComponent('ios');
      }
    }
  }

  private openPromptComponent(mobileType: 'ios' | 'android') {
    timer(3000)
      .pipe(take(1))
      .subscribe(() => this.bottomSheet.open(InstallPwaComponent, {
        data: {
          mobileType,
          promptEvent: this.promptEvent
        }
      }));
  }
}

