<h1 mat-dialog-title>{{'hotkeys' | transloco}}</h1>

<div matDialogContent>
  <table *ngIf="hotkeys.length > 0; else noHotkeys">
    <tbody>
      <tr *ngFor="let keys of hotkeys">
        <td>{{ keys[1] | transloco }}</td>

        <td class="text-right">
          <kbd [innerHTML]="display(keys[0])"></kbd>
        </td>

      </tr>
    </tbody>
  </table>

  <ng-template #noHotkeys>
    <h3>{{'no-hotkeys' | transloco}}</h3>
  </ng-template>
</div>